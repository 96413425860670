/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "./theme/fonts";
@import "./theme/spacing";

// Fix Ion Footer padding issue when there is tabs
ion-tabs {
    ion-footer ion-toolbar:last-child {
        padding-bottom: 0;
    }
}

// Image thumbnails
.thumbnail-wrapper>img {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

ion-card.question-card {

    ion-card-header {
        flex-direction: column !important;
    }

    ion-label.guidance-text {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }

    &.ios,
    &.md {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 5px;

        ion-card-content ion-item {
            --padding-start: 0.8rem;
        }

        ion-input,
        ion-textarea,
        ion-datetime {
            --placeholder-color: var(--ion-color-primary);
        }

        ion-datetime.datetime-placeholder {
            opacity: 0.5;
        }

        ion-range {
            --knob-background: var(--ion-color-primary);
        }
    }
}

ion-alert {

    .alert-tappable.sc-ion-alert-md,
    .alert-tappable.sc-ion-alert-ios {
        height: auto !important;
        contain: content;
    }

    .alert-radio-label.sc-ion-alert-md,
    .alert-radio-label.sc-ion-alert-ios {
        white-space: normal;
    }
}

.custom-popover {
    --box-shadow: 0px 0px 1px var(--ion-color-dark);

    &.section-popover {
        --max-height: 75%;
    }

    &.guidance-popover {
        --max-height: 60%;

        .guidance-text p {
            font-size: $font-xs;
        }
    }
}

.asset-information-modal {
    .guidance-wrapper {
        .guidance-text p {
            font-size: $font-sm;
        }
    }
}

ion-searchbar {

    &.ios,
    &.md {
        --color: var(--ion-color-primary);
        --icon-color: var(--ion-color-primary);
        --placeholder-color: var(--ion-color-primary);
    }
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
    background-color: var(--ion-background-color);
}

.allow-copy-text {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

ion-toolbar {
    background-color: var(--ion-background-color);
}

.native-input[type=date]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

ion-input[type="date"],
ion-input[type="time"] {

    &.ios:not(.has-value),
    &.md:not(.has-value) {
        &>input {
            align-self: center;
            display: flex;
            margin-left: 0.5rem;

            &::before {
                content: attr(placeholder);

                color: var(--placeholder-color);
                opacity: var(--placeholder-opacity);
            }
        }
    }
}

ion-button:focus {
    outline: none;
}

ion-accordion-group {
    ion-accordion {
        .ion-accordion-toggle-icon {
            color: var(--ion-color-primary);
        }
    }
}

ion-label {
    p {
        color: darkslategray !important;
    }

    ul {
        color: darkslategray !important;
    }

    ol {
        color: darkslategray !important;
    }
}

ion-modal.datetime-modal {
    --background: rgba(44, 39, 45, 0.2);

    &::part(content) {
        backdrop-filter: blur(3px);
    }

    ion-content {
        --background: transparent;
        --padding-top: 25vh;
        --padding-start: 1rem;
        --padding-end: 1rem;

        ion-datetime {
            border-radius: 8px;
        }
    }
}