$font-xxs : 0.7rem;
$font-xs : 0.8rem;
$font-sm:0.9rem;
$font-md:1.0rem;
$font-lg:1.1rem;
$font-xlg:1.2rem;

$font-weight-regular:400;
$font-weight-medium:500;
$font-weight-semi-bold:600;
$font-weight-bold:700;

.ios,
.md {
    .ion-label-font-xxs {
        font-size: $font-xxs;
    }

    .ion-label-font-xs {
        font-size: $font-xs;
    }

    .ion-label-font-sm {
        font-size: $font-sm;
    }

    .ion-label-font-md {
        font-size: $font-md;
    }

    .ion-label-font-lg {
        font-size: $font-lg;
    }

    .ion-label-font-xlg {
        font-size: $font-xlg;
    }

    .ion-font-weight-regular {
        font-weight: $font-weight-regular;
    }

    .ion-font-weight-medium {
        font-weight: $font-weight-medium;
    }

    .ion-font-weight-semi-bold {
        font-weight: $font-weight-semi-bold;
    }

    .ion-font-weight-bold {
        font-weight: $font-weight-bold;
    }
}