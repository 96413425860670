$spacer-elements:'margin',
'padding';

$sizes : 'top',
'left',
'bottom',
'right',
'vertical',
'horizontal';

$spacers : 0,
0.25rem,
0.5rem,
1rem,
1.5rem,
3rem;

@each $spacer-element in $spacer-elements {
    @each $size in $sizes {
        @for $i from 0 to length($spacers) {
            $spacer: nth($spacers, $i + 1);

            .ion-#{$spacer-element}-#{$size}-#{$i} {
                @if $size==horizontal {
                    #{$spacer-element}-left: $spacer !important;
                    #{$spacer-element}-right: $spacer !important;
                }

                @else if $size==vertical {
                    #{$spacer-element}-top: $spacer !important;
                    #{$spacer-element}-bottom: $spacer !important;
                }

                @else {
                    #{$spacer-element}-#{$size}: $spacer !important;
                }
            }
        }
    }
}